<template>
<section>
<div class="container">
<MccMncTable />
</div>
</section>
</template>

<script>
import MccMncTable from '@/components/MccMncTable.vue'

export default {
  name: 'MccMncPage',
    components: {
      MccMncTable
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
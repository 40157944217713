<template>
  <MccMncPage />
</template>

<script>
import MccMncPage from '@/components/MccMncPage.vue'


export default {
    data() {
    return { 
      search: '',
     
     }
  },
  name: 'About',
  components: {
    MccMncPage
  }
}
</script>
